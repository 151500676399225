


























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useAsset } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AssetLeasingResponseDto } from "@interface/master-asset";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectUnitLeasing extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: String, default: "" })
  dealerId!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  loading = false;
  options: Array<Option<AssetLeasingResponseDto>> = [];

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.preFetch();
  }

  @Watch("dealerId")
  onChangeDealer(): void {
    this.preFetch();
  }

  preFetch(): void {
    const params = new RequestQueryParams();
    params.search = this.buildFindByDealer(this.dealerId);
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAllAssetForLeasing, toAssetLeasingOptions } = useAsset();
    this.loading = true;
    findAllAssetForLeasing(params)
      .then(res => {
        this.options = toAssetLeasingOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useAsset();
    const params = new RequestQueryParams();
    const query: Array<string> = [];
    const builder = new SearchBuilder();

    if (this.dealerId) {
      query.push(this.buildFindByDealer(this.dealerId));
    }

    if (val) {
      query.push(filterBy({ unitCode: val }));
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AssetLeasingResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildFindByDealer(dealerId: string): string {
    const builder = new SearchBuilder();
    if (!dealerId) return "";
    return builder
      .push(["receivingItemLine.receivingItem.supplier.secureId", dealerId])
      .build();
  }
}
