var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-input-search", {
    attrs: { value: _vm.value, loading: _vm.loading },
    on: { change: _vm.onChange, search: _vm.savePolicy },
    scopedSlots: _vm._u([
      {
        key: "enterButton",
        fn: function() {
          return [_c("a-button", { attrs: { icon: "save", type: "primary" } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }