var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          size: "small",
          pagination: false,
          columns: _vm.columns,
          "data-source": _vm.store.form.periodList,
          scroll: { x: "calc(100% + 500px)", y: 600 },
          "row-class-name": function(_, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleGenerate }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc(
                            "lbl_generate_x_row",
                            _vm.store.form.leasingPeriod,
                            { x: _vm.store.form.leasingPeriod }
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "nullable",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(text || "-") + " ")]
            }
          },
          {
            key: "currency",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")]
            }
          },
          {
            key: "chequeNumber",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: { "allow-clear": "", disabled: !!row.cashInOutId },
                  model: {
                    value: row.chequeNumber,
                    callback: function($$v) {
                      _vm.$set(row, "chequeNumber", $$v)
                    },
                    expression: "row.chequeNumber"
                  }
                })
              ]
            }
          },
          {
            key: "paymentType",
            fn: function(text, row) {
              return [
                !!row.cashInOutId
                  ? [_vm._v(" " + _vm._s(row.paymentType) + " ")]
                  : _c("a-select", {
                      staticClass: "w-100",
                      attrs: {
                        "allow-clear": "",
                        "show-search": "",
                        options: _vm.paymentTypeOptions
                      },
                      model: {
                        value: row.paymentType,
                        callback: function($$v) {
                          _vm.$set(row, "paymentType", $$v)
                        },
                        expression: "row.paymentType"
                      }
                    })
              ]
            }
          },
          {
            key: "paymentDate",
            fn: function(text, row) {
              return [
                _c("a-date-picker", {
                  attrs: {
                    format: _vm.DEFAULT_DATE_FORMAT,
                    disabled: !!row.cashInOutId
                  },
                  model: {
                    value: row.paymentDate,
                    callback: function($$v) {
                      _vm.$set(row, "paymentDate", $$v)
                    },
                    expression: "row.paymentDate"
                  }
                })
              ]
            }
          },
          {
            key: "principalCost",
            fn: function(text, row) {
              return [
                _vm.disabledForm
                  ? _c("span", [
                      _vm._v(_vm._s(_vm._f("currency")(row.principalCost)))
                    ])
                  : _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        parser: _vm.reverseFormatNumber,
                        formatter: _vm.formatterNumber,
                        precision: _vm.storeBaseDecimalPlace,
                        disabled: !!row.cashInOutId
                      },
                      on: {
                        change: function() {
                          return _vm.calcPaymentAndBalance(row)
                        }
                      },
                      model: {
                        value: row.principalCost,
                        callback: function($$v) {
                          _vm.$set(row, "principalCost", _vm._n($$v))
                        },
                        expression: "row.principalCost"
                      }
                    })
              ]
            }
          },
          {
            key: "interestCost",
            fn: function(text, row) {
              return [
                _vm.disabledForm
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(row.interestCost)) + " "
                      )
                    ])
                  : _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        parser: _vm.reverseFormatNumber,
                        formatter: _vm.formatterNumber,
                        precision: _vm.storeBaseDecimalPlace,
                        disabled: !!row.cashInOutId
                      },
                      on: {
                        change: function() {
                          return _vm.calcPaymentAndBalance(row)
                        }
                      },
                      model: {
                        value: row.interestCost,
                        callback: function($$v) {
                          _vm.$set(row, "interestCost", _vm._n($$v))
                        },
                        expression: "row.interestCost"
                      }
                    })
              ]
            }
          },
          {
            key: "paymentRealizationDate",
            fn: function(text, row) {
              return [
                _c("a-date-picker", {
                  attrs: {
                    format: _vm.DEFAULT_DATE_FORMAT,
                    disabled: !_vm.isAllowCreatePayment(row)
                  },
                  model: {
                    value: row.paymentRealizationDate,
                    callback: function($$v) {
                      _vm.$set(row, "paymentRealizationDate", $$v)
                    },
                    expression: "row.paymentRealizationDate"
                  }
                })
              ]
            }
          },
          {
            key: "cashInOutDocumentNumber",
            fn: function(text, row) {
              return [
                !!row.cashInOutId
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "cash-management.cash-in-out.edit",
                            params: {
                              id: row.cashInOutId
                            }
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { staticClass: "p-0", attrs: { type: "link" } },
                          [
                            _vm._v(
                              " " + _vm._s(row.cashInOutDocumentNumber) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("span", [_vm._v(" " + _vm._s("-") + " ")])
              ]
            }
          },
          {
            key: "operation",
            fn: function(text, row) {
              return [
                _c(
                  "a-space",
                  [
                    _vm.$can("create", "payment-leasing") &&
                    _vm.isAllowCreatePayment(row)
                      ? [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: row.loading.settle,
                                disabled: row.cashInOutId
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleCreatePayment(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.cashInOutId
                                      ? _vm.$t("lbl_paid")
                                      : _vm.$t("lbl_create_payment")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      : _vm.$can("cancel", "payment-leasing") &&
                        !!row.cashInOutId
                      ? [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: _vm.$t("pop_confirmation"),
                                "ok-text": _vm.$t("lbl_yes"),
                                "cancel-text": _vm.$t("lbl_no")
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleCancelPayment(row)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: row.loading.cancel
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_cancel_payment")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", {
                total: _vm.store.form.periodList.length
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }