import { render, staticRenderFns } from "./SelectInsurance.vue?vue&type=template&id=31b23e09&"
import script from "./SelectInsurance.vue?vue&type=script&lang=ts&"
export * from "./SelectInsurance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31b23e09')) {
      api.createRecord('31b23e09', component.options)
    } else {
      api.reload('31b23e09', component.options)
    }
    module.hot.accept("./SelectInsurance.vue?vue&type=template&id=31b23e09&", function () {
      api.rerender('31b23e09', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/custom/select/SelectInsurance.vue"
export default component.exports