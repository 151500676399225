


















import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAccountChild extends Vue {
  @Prop({ required: false, type: Object })
  value!: LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams();
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllChildAccount, toOptions } = useCoa();
    this.loading = true;
    findAllChildAccount(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useCoa();
    const params = new RequestQueryParams();
    params.search = filterBy({
      code: val,
      description: val,
    });

    this.fetchOptions(params);
  }

  findOption(
    value?: LabelInValue
  ): Option<AccountingAccountResponseDto> | undefined {
    return this.options.find(e => value?.key === e.value);
  }
}
