













































import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { debounce } from "@/helpers/debounce";
import { useContactData, useInvoiceAPPrepayment } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceApPrepayment } from "@/models/interface/InvoiceApPrepayment.interface";
import { AssetItem, State } from "@/store/account-payable/leasing/types";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      disabledForm: "leasingStore/disabledForm",
    }),
  },
})
export default class FormUnitCode extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reverseFormatPercent = reverseFormatPercent;
  formatterPercent = formatterPercent;
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  storeBaseDecimalPlace!: number;
  store!: State;
  disabledForm!: boolean;

  dealerOptions: Option[] = [];

  columns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "assetUnitCode",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_dealer_name"),
      dataIndex: "dealer",
      key: "dealer",
      scopedSlots: { customRender: "dealer" },
    },
    {
      title: this.$t("lbl_tax_invoice_number_dp"),
      dataIndex: "taxInvoiceNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_prepayment_date"),
      dataIndex: "prepaymentDate",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_tax_invoice_number_payment"),
      dataIndex: "paymentTaxInvoiceNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_date_payment_invoice"),
      dataIndex: "paymentTaxInvoiceDate",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_repayment_percentage"),
      dataIndex: "portion",
      scopedSlots: { customRender: "portion" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_cost_price_unit"),
      dataIndex: "costPricePerUnit",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_nett_finance"),
      dataIndex: "nettFinance",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_interest"),
      dataIndex: "interest",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_provision"),
      dataIndex: "provision",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_insurance"),
      dataIndex: "insurancePremium",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_residue"),
      dataIndex: "residue",
      customRender: text => currencyFilter(text),
    },
  ];

  created(): void {
    this.getDealerOptions();
  }

  fetchPrepayment(
    unitId: string,
    cb: (res: Array<InvoiceApPrepayment>) => void
  ): void {
    const { findAllPrepaymentLeasing } = useInvoiceAPPrepayment();
    findAllPrepaymentLeasing(unitId).then(cb);
  }

  async getDealerList(search?: string): Promise<Option[]> {
    const { findAllDealer, toSupplierOptions, filterBy } = useContactData();
    const params = new RequestQueryParams();
    if (search) {
      params.search = filterBy({
        firstName: search,
        lastName: search,
      });
    }
    const response = await findAllDealer(params);
    return toSupplierOptions(response.data);
  }

  getDealerOptions(): void {
    this.getDealerList().then(response => {
      this.dealerOptions = response;
    });
  }

  onSearchDealer(record: AssetItem, val?: string): void {
    debounce(() => {
      record.isSearchDealer = true;

      record.loadingDealer = true;
      this.getDealerList(val)
        .then(response => {
          record.dealerOptions = response;
        })
        .finally(() => {
          record.loadingDealer = false;
        });
    });
  }

  onChangeDealer(record: AssetItem, val?: LabelInValue): void {
    if (!val) {
      record.isSearchDealer = false;
      return;
    }
  }
}
