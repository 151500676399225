










































import { State } from "@/store/account-payable/leasing/types";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
    }),
    ...mapGetters({
      sumInsurancePeriod: "leasingStore/sumInsurancePeriod",
      sumPrincipalCost: "leasingStore/sumPrincipalCost",
      sumInterestCost: "leasingStore/sumInterestCost",
      sumPaymentCost: "leasingStore/sumPaymentCost",
    }),
  },
})
export default class DisplayTotal extends Vue {
  store!: State;
  sumInsurancePeriod!: number;
  sumPrincipalCost!: number;
  sumInterestCost!: number;
  sumPaymentCost!: number;
}
