import DisplayTotal from "./DisplayTotal.vue";
import FieldPolicyNumber from "./FieldPolicyNumber.vue";
import FormHeader from "./FormHeader.vue";
import FormPayment from "./FormPayment.vue";
import FormUnitCode from "./FormUnitCode.vue";
import SelectLeasing from "./SelectLeasing.vue";
import SelectUnitLeasing from "./SelectUnitLeasing.vue";

export {
  DisplayTotal,
  FieldPolicyNumber,
  FormHeader,
  FormPayment,
  FormUnitCode,
  SelectLeasing,
  SelectUnitLeasing,
};
