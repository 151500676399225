var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16], type: "flex", justify: "space-around" } },
    [
      _c(
        "a-col",
        { attrs: { span: 6 } },
        [
          _c("a-statistic", {
            attrs: {
              title: _vm.$t("lbl_total_insurance"),
              value: _vm.store.form.insurancePremium
            },
            scopedSlots: _vm._u([
              {
                key: "formatter",
                fn: function(ref) {
                  var value = ref.value
                  return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 6 } },
        [
          _c("a-statistic", {
            attrs: {
              title: _vm.$t("lbl_total_principal_cost"),
              value: _vm.sumPrincipalCost
            },
            scopedSlots: _vm._u([
              {
                key: "formatter",
                fn: function(ref) {
                  var value = ref.value
                  return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 6 } },
        [
          _c("a-statistic", {
            attrs: {
              title: _vm.$t("lbl_total_interest_cost"),
              value: _vm.sumInterestCost
            },
            scopedSlots: _vm._u([
              {
                key: "formatter",
                fn: function(ref) {
                  var value = ref.value
                  return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 6 } },
        [
          _c("a-statistic", {
            attrs: {
              title: _vm.$t("lbl_total_installment"),
              value: _vm.sumPaymentCost
            },
            scopedSlots: _vm._u([
              {
                key: "formatter",
                fn: function(ref) {
                  var value = ref.value
                  return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }