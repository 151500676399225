var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          size: "small",
          "data-source": _vm.store.form.assetList,
          columns: _vm.columns,
          scroll: { x: "calc(100% + 3000px)", y: 600 },
          "row-class-name": function(_, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          },
          pagination: false
        },
        scopedSlots: _vm._u([
          {
            key: "portion",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    parser: _vm.reverseFormatPercent,
                    formatter: _vm.formatterPercent,
                    precision: _vm.storeBaseDecimalPlace,
                    disabled: _vm.disabledForm
                  },
                  model: {
                    value: row.portion,
                    callback: function($$v) {
                      _vm.$set(row, "portion", _vm._n($$v))
                    },
                    expression: "row.portion"
                  }
                })
              ]
            }
          },
          {
            key: "dealer",
            fn: function(text, row) {
              return [
                _c("a-select", {
                  staticClass: "w-100",
                  attrs: {
                    options: row.isSearchDealer
                      ? row.dealerOptions
                      : _vm.dealerOptions,
                    loading: row.loadingDealer,
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_dealer_name")
                    }),
                    "label-in-value": "",
                    "show-search": "",
                    "allow-clear": "",
                    "dropdown-match-select-width": false,
                    "filter-option": false
                  },
                  on: {
                    search: function($event) {
                      return _vm.onSearchDealer(row, $event)
                    },
                    change: function($event) {
                      return _vm.onChangeDealer(row, $event)
                    }
                  },
                  model: {
                    value: row.dealer,
                    callback: function($$v) {
                      _vm.$set(row, "dealer", $$v)
                    },
                    expression: "row.dealer"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", {
                total: _vm.store.form.assetList.length
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }